import React from "react"
import {useTranslation} from "react-i18next"
import {StarIcon as StarIconOutline, TrashIcon} from "@heroicons/react/24/outline"
import {LinkIcon, StarIcon as StarIconSolid} from "@heroicons/react/24/solid"
import {twMerge} from "tailwind-merge"

import {IconButton} from "../../../components/Button.tsx"
import {Table, TRowCheckingProps} from "../../../components/Table"
import {TOrderBy} from "../../../components/Table/shared.ts"
import {Tooltip} from "../../../components/Tooltip.tsx"
import {useDeprioritizeProspectMutation, usePrioritizeProspectMutation} from "../../../queries/prospects.ts"
import requestError from "../../../services/requestError.tsx"
import {
  AAdminProspect,
  AProspectApprovalStatuses,
  AProspectDisapproveReasons,
  ASaasCompanyUserProspect,
  ASalesPersonProspect,
} from "../../../services/types.generated.ts"
import {getFullName} from "../../../utils"
import {enumTranslKey} from "../../../utils/i18n.tsx"
import {DeletingContext, EditingContext} from "./context.ts"
import {RejectedReason} from "./RejectedReason.tsx"

type TTableProspect = AAdminProspect | ASaasCompanyUserProspect | ASalesPersonProspect

export type TAdminTableColumn =
  | "actions"
  | "company"
  | "owner"
  | "segment"
  | "city"
  | "contact person"
  | "position"
  | "phone"
  | "email"
  | "rejected reason"
  | "crm"

export const DataTable: React.FC<
  {
    data: TTableProspect[]
    isLoading: boolean
    isPrioritizeActionVisible?: boolean
    orderBy: TOrderBy<TAdminTableColumn>
    setOrderBy: (newOrderBy: TOrderBy<TAdminTableColumn>) => void
    refetch: () => void
    isReadOnly?: boolean
  } & TRowCheckingProps
> = ({data, isLoading, isPrioritizeActionVisible, isReadOnly, setOrderBy, orderBy, refetch, checkRow, checkedRows}) => {
  const {t} = useTranslation()

  const editContext = EditingContext.useContext()
  const deleteContext = DeletingContext.useContext()

  const prioritizeMutation = usePrioritizeProspectMutation()
  const deprioritizeMutation = useDeprioritizeProspectMutation()

  const handleTogglePriority = React.useCallback(
    async (prospect: TTableProspect) => {
      try {
        await (prospect.prioritized ? deprioritizeMutation : prioritizeMutation).mutateAsync(prospect.id)
        refetch()
      } catch (e) {
        requestError(e)
      }
    },
    [deprioritizeMutation, prioritizeMutation, refetch]
  )

  return (
    <Table<TAdminTableColumn, TTableProspect>
      data={data}
      loading={isLoading}
      orderBy={orderBy}
      onOrder={setOrderBy}
      checkedRows={checkedRows}
      onCheckRow={checkRow}
      columnsMeta={[
        !isReadOnly && Table.rowCheckColumn,
        !isReadOnly && {
          column: "actions",
          size: "min-content",
          HeaderCellValue: () => t("Prospects_Table_Actions"),
          CellValue: ({row}) => {
            return (
              <div className={"flex gap-4"}>
                {isPrioritizeActionVisible && (
                  <Tooltip
                    buttonNode={
                      <IconButton
                        className={"text-cr-black hover:text-cr-blue"}
                        noEdges
                        onClick={() => handleTogglePriority(row)}
                      >
                        {row.prioritized ? (
                          <StarIconSolid className={"size-4 text-cr-blue"} />
                        ) : (
                          <StarIconOutline className={"size-4"} />
                        )}
                      </IconButton>
                    }
                    openDelay={200}
                  >
                    <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                      {row.prioritized ? t("Prospects_Table_RemovePriorityMark") : t("Prospects_Table_MarkAsPriority")}
                    </div>
                  </Tooltip>
                )}
                <Tooltip
                  buttonNode={
                    <IconButton
                      className={"text-cr-black hover:text-cr-red"}
                      noEdges
                      onClick={() => deleteContext.setValue(row)}
                    >
                      <TrashIcon className={"size-4"} />
                    </IconButton>
                  }
                  openDelay={200}
                >
                  <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                    {t("Prospects_Table_Delete")}
                  </div>
                </Tooltip>
              </div>
            )
          },
        },
        {
          column: "rejected reason",
          size: "min-content",
          HeaderCellValue: () => null,
          CellValue: ({row}) => (
            <RejectedReason
              reason={
                row.disapprove_reason === AProspectDisapproveReasons.Other
                  ? row.disapprove_reason_text
                  : row.disapprove_reason
              }
            />
          ),
        },
        data.some(row => row.crm_synced) && {
          column: "crm",
          size: "min-content",
          align: "center",
          CellValue: ({row}) => (row.crm_synced ? <LinkIcon className={"h-4 w-4 text-cr-black"} /> : null),
        },
        "added_by" in (data[0] ?? {}) && {
          column: "owner",
          HeaderCellValue: () => t("Prospects_Table_Owner"),
          CellValue: ({row}) => ("added_by" in row ? row.added_by : null),
        },
        {
          column: "company",
          size: "max-content",
          sortFn: true,
          HeaderCellValue: () => t("Prospects_Table_Company"),
          CellValue: ({row}) => <span>{row.organization_name ?? "-"}</span>,
        },
        {
          column: "segment",
          size: "max-content",
          HeaderCellValue: () => t("Prospects_Table_Segment"),
          CellValue: ({row}) => (
            <span>{row.segment?.map(segment => t(enumTranslKey("Segment", segment), segment)).join(", ") ?? "-"}</span>
          ),
        },
        {
          column: "city",
          size: "max-content",
          sortFn: true,
          HeaderCellValue: () => t("Prospects_Table_City"),
          CellValue: ({row}) => <span>{[row.city, row.country?.name].filter(Boolean).join(", ") || "-"}</span>,
        },
        {
          column: "contact person",
          size: "max-content",
          HeaderCellValue: () => t("Prospects_Table_ContactPerson"),
          CellValue: ({row}) => (
            <span>
              {getFullName({first_name: row.contact_person_first_name, last_name: row.contact_person_last_name}) || "-"}
            </span>
          ),
        },
        {column: "position", sortFn: true, HeaderCellValue: () => t("Prospects_Table_Position")},
        {
          column: "phone",
          size: "max-content",
          HeaderCellValue: () => t("Prospects_Table_Phone"),
          CellValue: ({row}) => <span>{row.phone_number ?? "-"}</span>,
        },
        {column: "email", sortFn: true, HeaderCellValue: () => t("Prospects_Table_Email")},
      ]}
    >
      {({data}) => (
        <>
          {data.length ? (
            data.map(row => (
              <Table.Row
                key={row.id}
                onClick={isReadOnly ? undefined : () => editContext.setValue(row)}
                row={row}
                className={twMerge([
                  row.id === editContext.value?.id && "bg-cr-blue-super-light",
                  row.approval_status === AProspectApprovalStatuses.Disapproved && "text-cr-red",
                ])}
              />
            ))
          ) : (
            <div className={"col-span-full py-7 text-center text-sm"}>{t("Prospects_NoProspects_TableCaption")}</div>
          )}
        </>
      )}
    </Table>
  )
}
