import React, {forwardRef, InputHTMLAttributes} from "react"
import {Controller, FieldPath, FieldValues, useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {pickProps} from "../../utils/types"
import {Link} from "../Link"
import {TBaseSharedProps, TConnectedField} from "./components"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "./FieldLabel"
import {InputBase, TInputSharedProps} from "./Input"

type TFileInputSharedProps = TBaseSharedProps &
  Omit<TInputSharedProps, "type" | "Icon" | "children"> &
  Pick<InputHTMLAttributes<HTMLInputElement>, "accept">

type TFileInputBaseProps = TFileInputSharedProps & InputHTMLAttributes<HTMLInputElement>

export type TFileInputConnectedProps<T extends FieldValues, N extends FieldPath<T>> = TFileInputSharedProps &
  TConnectedField<T, N>

export const FileInputBase = forwardRef<
  HTMLInputElement,
  TFileInputBaseProps & {
    fileName?: string
    url?: string
  }
>(({fileName, url, ...props}, ref) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col gap-2 text-left"}>
      <InputBase
        ref={ref}
        type={"file"}
        inputClassName={twMerge([
          "p-1.5",
          "cursor-pointer file:cursor-pointer file:-ml-0.5 file:mr-2 file:px-3 file:py-1 file:text-sm file:text-cr-grey-80 file:font-medium file:rounded-md file:bg-cr-grey-5 file:hover:bg-cr-grey-15 file:transition-colors file:border-0",
        ])}
        {...props}
      />
      {fileName && (
        <div className={"text-sm"}>
          {t("T_Currently uploaded")}:{" "}
          <Link to={url} target={"_blank"}>
            {decodeURIComponent(fileName)}
          </Link>
        </div>
      )}
    </div>
  )
})
FileInputBase.displayName = "FileInput"

export function FileInputConnected<T extends FieldValues, N extends FieldPath<T>>(
  props: TFileInputConnectedProps<T, N>
) {
  const {watch} = useFormContext<T>()

  const url = watch(`${props.name}_url` as any) ?? ""
  const fileName = /.*\/(.*)$/.exec(url)?.[1]

  return (
    <Controller
      name={props.name}
      render={({field, fieldState: {error}}) => {
        return (
          <FileInputBase
            {...field}
            value={field.value?.fileName}
            onChange={e => {
              field.onChange(e.target.files?.[0])
            }}
            url={url}
            fileName={fileName}
            hasError={!!error}
            {...props}
          />
        )
      }}
    />
  )
}

export function FileInputField<T extends FieldValues, N extends FieldPath<T>>(
  props: TFieldLabelProps & TFileInputConnectedProps<T, N>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const inputProps = pickFileInputConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <FileInputConnected {...inputProps} />
    </FieldLabel>
  )
}

function pickFileInputConnectedProps<T extends FieldValues, N extends FieldPath<T>>(
  props: React.ComponentProps<typeof FileInputField<T, N>>
) {
  return pickProps<TFileInputConnectedProps<T, N>>({
    name: true,
    hasError: true,
    debounced: true,
    borderClassName: true,
    ghost: true,
    options: true,
    inputClassName: true,
    placeholder: true,
    readOnly: true,
    disabled: true,
    accept: true,
  })(props)
}
