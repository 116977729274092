import React from "react"
import {useTranslation} from "react-i18next"

import {DropdownBase} from "./formElements/Dropdown"

const DEFAULT_PAGE_SIZES = [5, 10, 25, 50, 100] as const

export const RowCountPicker: React.FC<{
  rowCount: number
  pageSizes?: number[]
  total?: number
  autoHide?: boolean
  onChange: (newRowCount: number) => void
  openUp?: boolean
}> = ({rowCount, onChange, pageSizes = DEFAULT_PAGE_SIZES, total, autoHide, openUp}) => {
  const {t} = useTranslation()

  const options = React.useMemo(() => {
    return Array.from(new Set([...pageSizes, rowCount]))
      .sort((A, B) => A - B)
      .map(count => ({title: count, value: count}))
  }, [pageSizes, rowCount])

  const handleChange = React.useCallback(
    (newRowCount: number | undefined) => {
      if (newRowCount == null) {
        return
      }
      onChange(newRowCount)
    },
    [onChange]
  )

  const isTotalLessThanSmallestPage = (total ?? Number.POSITIVE_INFINITY) <= options[0].value

  if (autoHide && isTotalLessThanSmallestPage) {
    return null
  }

  return (
    <div className={"flex items-center justify-end gap-2 text-sm"}>
      {t("RowCountPicker_Showing")}
      <div>
        <DropdownBase value={rowCount} onChange={handleChange} options={options} openUp={openUp} />
      </div>
      {t("RowCountPicker_Rows")}
    </div>
  )
}
