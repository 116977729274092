import React from "react"
import {useTranslation} from "react-i18next"
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStatus,
} from "@floating-ui/react"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {CheckCircleIcon, ExclamationTriangleIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {i18n} from "../i18n"
import type Button from "./Button"
import {ButtonLoading} from "./Button"

export type TModalSize = "xl" | "lg" | "sm"

type TUseModalParams = {
  isOpen: boolean
  onClose: () => void
  disableClickOutsideClose?: boolean
}

export type TModalProps = TUseModalParams & {
  children?: React.ReactNode
  title?: React.ReactNode
  className?: string
  disableCloseButton?: boolean
  size?: TModalSize
}

const useModal = ({isOpen, onClose, disableClickOutsideClose}: TUseModalParams) => {
  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (!open) {
        onClose()
      }
    },
    [onClose]
  )

  const data = useFloating({open: isOpen, onOpenChange: handleOpenChange})
  const context = data.context

  const dismiss = useDismiss(context, {outsidePressEvent: "mousedown", enabled: !disableClickOutsideClose})
  const role = useRole(context)
  const {getFloatingProps} = useInteractions([dismiss, role])

  return React.useMemo(() => ({getFloatingProps, ...data}), [getFloatingProps, data])
}

const Modal: React.FC<TModalProps> = ({
  children,
  title,
  isOpen = true,
  onClose,
  className,
  disableClickOutsideClose,
  disableCloseButton,
  size,
}) => {
  const {t} = useTranslation()

  const {context, refs, getFloatingProps} = useModal({
    isOpen,
    onClose,
    disableClickOutsideClose,
  })

  const {isMounted, status} = useTransitionStatus(context, {
    duration: {
      open: 300,
      close: 200,
    },
  })

  if (!isMounted) {
    return null
  }

  return (
    <FloatingPortal>
      <FloatingOverlay className={"relative z-[100]"} lockScroll>
        <div
          className={twMerge([
            "fixed inset-0 bg-cr-grey-50 bg-opacity-75 transition-opacity",
            status === "open" ? "opacity-100 duration-300" : "opacity-0 duration-200",
          ])}
        />
        <div className={"fixed inset-0 z-10 overflow-y-auto"}>
          <div className={"flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"}>
            <FloatingFocusManager context={context} returnFocus={false}>
              <div
                className={twMerge([
                  "relative w-full transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:m-8 sm:p-6",
                  size === "sm" && "max-w-xl",
                  size === "lg" && "max-w-3xl",
                  size === "xl" && "max-w-screen-xl",
                  status === "open"
                    ? "translate-y-0 opacity-100 duration-300 sm:scale-100"
                    : "translate-y-4 opacity-0 duration-200 sm:translate-y-0 sm:scale-95",
                  className,
                ])}
                data-testid={"modal"}
                ref={refs.setFloating}
                {...getFloatingProps()}
              >
                {!disableCloseButton && (
                  <div className={"absolute right-0 top-0 pr-4 pt-4"}>
                    <button
                      type={"button"}
                      className={
                        "rounded-md bg-white text-cr-grey-50 transition-colors hover:text-cr-grey-80 focus:outline-none focus:ring-2 focus:ring-cr-blue focus:ring-offset-2"
                      }
                      data-testid={"modal-close-button"}
                      onClick={onClose}
                    >
                      <span className={"sr-only"}>{t("T_Close")}</span>
                      <XMarkIcon className={"h-6 w-6"} aria-hidden={"true"} />
                    </button>
                  </div>
                )}
                {title && <h3 className={"mb-8 text-center text-2xl font-semibold text-cr-grey-80"}>{title}</h3>}
                {children}
              </div>
            </FloatingFocusManager>
          </div>
        </div>
      </FloatingOverlay>
    </FloatingPortal>
  )
}

type TConfirmModalVariant = "info" | "warning" | "error"

const variantModalIconMap = {
  info: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
  error: ExclamationTriangleIcon,
} as const satisfies {
  [variant in TConfirmModalVariant]: React.ComponentType<{className?: string}>
}
const confirmModalConfirmButtonColorMap = {
  info: "blue",
  warning: "blue",
  error: "red",
} as const satisfies {
  [variant in TConfirmModalVariant]: React.ComponentProps<typeof Button>["color"]
}

export const VariantModal: React.FC<
  {variant: TConfirmModalVariant; buttons?: React.ReactNode; noIcon?: boolean} & TModalProps
> = ({variant, className, title, children, buttons, noIcon, ...modalProps}) => {
  const Icon = variantModalIconMap[variant]

  return (
    <Modal size={"sm"} {...modalProps} className={className}>
      <div className={"flex flex-col gap-10"}>
        <div className={"flex items-start gap-4"}>
          {!noIcon && (
            <div
              className={twMerge([
                "shrink-0 rounded-full p-2",
                variant === "info" && "bg-cr-blue-light text-cr-blue",
                variant === "warning" && "bg-cr-yellow-light text-cr-yellow",
                variant === "error" && "bg-cr-red-light text-cr-red",
              ])}
            >
              <Icon className={"h-6 w-6"} />
            </div>
          )}
          <div className={"flex grow flex-col gap-2"}>
            {title && <span className={"text-lg font-bold"}>{title}</span>}
            {children && <div className={"text-cr-grey-50"}>{children}</div>}
          </div>
        </div>
        <div className={"flex justify-end gap-4"}>{buttons}</div>
      </div>
    </Modal>
  )
}

export const ConfirmModal: React.FC<
  {
    variant?: TConfirmModalVariant
    cancelButtonText?: React.ReactNode
    cancelButtonIcon?: React.ReactNode
    confirmButtonText?: React.ReactNode
    confirmButtonIcon?: React.ReactNode
    isConfirmDisabled?: boolean
    onConfirm: () => unknown
    noIcon?: boolean
    flipButtons?: boolean
  } & TModalProps
> = ({
  children,
  onConfirm,
  onClose,
  variant = "info",
  flipButtons,
  disableClickOutsideClose = true,
  disableCloseButton = true,
  cancelButtonText = i18n.t("ConfirmModal_CancelButton"),
  cancelButtonIcon,
  confirmButtonText = i18n.t("ConfirmModal_ConfirmButton"),
  confirmButtonIcon,
  isConfirmDisabled,
  className,
  ...modalProps
}) => {
  const handleClose = React.useCallback(async () => {
    onClose()
  }, [onClose])

  const handleConfirm = React.useCallback(async () => {
    const result = await onConfirm()

    if (result === false) {
      return
    }

    onClose()
  }, [onConfirm, onClose])

  const cancelButtonProps = React.useMemo(
    () =>
      ({
        onClick: handleClose,
        iconLeft: cancelButtonIcon,
      }) satisfies Partial<React.ComponentProps<typeof ButtonLoading>>,
    [cancelButtonIcon, handleClose]
  )
  const confirmButtonProps = React.useMemo(
    () =>
      ({
        onClick: handleConfirm,
        iconLeft: confirmButtonIcon,
        disabled: isConfirmDisabled,
      }) satisfies Partial<React.ComponentProps<typeof ButtonLoading>>,
    [confirmButtonIcon, handleConfirm, isConfirmDisabled]
  )

  return (
    <VariantModal
      variant={variant}
      size={"sm"}
      {...modalProps}
      onClose={onClose}
      disableClickOutsideClose={disableClickOutsideClose}
      disableCloseButton={disableCloseButton}
      className={className}
      buttons={
        <>
          <ButtonLoading
            variant={"outlined"}
            color={"gray"}
            {...(flipButtons ? confirmButtonProps : cancelButtonProps)}
          >
            {flipButtons ? confirmButtonText : cancelButtonText}
          </ButtonLoading>
          <ButtonLoading
            color={confirmModalConfirmButtonColorMap[variant]}
            {...(flipButtons ? cancelButtonProps : confirmButtonProps)}
          >
            {flipButtons ? cancelButtonText : confirmButtonText}
          </ButtonLoading>
        </>
      }
    >
      {children}
    </VariantModal>
  )
}

export default Modal
