import React from "react"

import {EOrderDirection} from "../../../components/Table/shared"
import {sortDate} from "../../../components/Table/sortFunctions"
import {
  TAdminsProspectsIteration,
  TCompanyProspectsIteration,
  TSaasCompanyProspectsIteration,
  TSalespersonProspectsIteration,
} from "../../../queries/prospects"
import {
  AAdminsProspectsSalesCycle,
  ACompanyProspectsSalesCycle,
  ASaasCompanyProspectsSalesCycle,
  ASalesPersonProspectsSalesCycle,
} from "../../../services/types.generated"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"

type TProspectsSalesCycle =
  | ACompanyProspectsSalesCycle
  | AAdminsProspectsSalesCycle
  | ASaasCompanyProspectsSalesCycle
  | ASalesPersonProspectsSalesCycle

type TProspectsIteration =
  | TCompanyProspectsIteration
  | TAdminsProspectsIteration
  | TSaasCompanyProspectsIteration
  | TSalespersonProspectsIteration

export function useActiveIteration<T extends TProspectsSalesCycle>(salesCycle: T) {
  const [activeIteration, setActiveIteration] = React.useState<T["sales_cycle_iterations"][number] | undefined>(
    undefined
  )

  React.useEffect(() => {
    setActiveIteration(iteration => {
      const sameIteration = salesCycle.sales_cycle_iterations.find(
        salesCycleIteration => salesCycleIteration.id === iteration?.id
      )

      if (sameIteration) {
        return sameIteration
      }

      return [...salesCycle.sales_cycle_iterations].sort(
        sortDate<T["sales_cycle_iterations"][number]>(iteration => apiDateToJS(iteration.start_date))(
          EOrderDirection.DESC
        )
      )[0]
    })
  }, [salesCycle])

  return [activeIteration, setActiveIteration] as const
}

export function useActiveAssignment<T extends TProspectsIteration>(activeIteration: T | undefined) {
  const [activeAssignment, setActiveAssignment] = React.useState<T["assignments"][number] | undefined>(undefined)

  React.useEffect(() => {
    if (!activeIteration) {
      setActiveAssignment(undefined)
      return
    }

    setActiveAssignment(currentAssignment => {
      const sameSalespersonAssignment = activeIteration.assignments.find(a => a.id === currentAssignment?.id)

      return sameSalespersonAssignment ?? activeIteration.assignments[0]
    })
  }, [activeIteration])

  return [activeAssignment, setActiveAssignment] as const
}
