import React from "react"

export type TStateContext<T> = {
  value: T
  setValue: React.Dispatch<React.SetStateAction<T>>
}
export type TStateContextFilled<T> = TStateContext<T> & {value: NonNullable<T>}

export function createStateContext<T>(name: string) {
  const context = React.createContext<TStateContext<T> | undefined>(undefined)

  return {
    useContext: (): TStateContext<T> => {
      const ctx = React.useContext(context)

      if (ctx === undefined) {
        throw new Error(`Context '${name}' not found`)
      }

      return ctx
    },
    useContextOrDie: (): TStateContextFilled<T> => {
      const ctx = React.useContext(context)

      if (ctx === undefined) {
        throw new Error(`Context '${name}' not found`)
      }
      if (ctx.value == null) {
        throw new Error(`Context '${name}' not filled`)
      }

      return ctx as TStateContextFilled<T>
    },
    Provider: context.Provider,
    useProviderValue: (initialValue: T): TStateContext<T> => {
      const [value, setValue] = React.useState<T>(initialValue)
      return {value, setValue}
    },
  }
}
