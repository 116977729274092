import {
  ALeadCompanySizes,
  AProspectLastChangeRanges,
  ASalesCycleIdealCustomerProfiles,
  ASalesCycleProspectStages,
  ASalesCycleYearsOfMarkets,
} from "../services/types.generated.ts"
import {TTranslatedEnums} from "./i18n.tsx"

export const enumOrders: {
  [key in keyof TTranslatedEnums]?: {[k in keyof TTranslatedEnums[key]]: number}
} = {
  ProspectStage: enumOrder(
    ASalesCycleProspectStages.FirstOutreach,
    ASalesCycleProspectStages.SecondOutreach,
    ASalesCycleProspectStages.Followup1,
    ASalesCycleProspectStages.Followup2,
    ASalesCycleProspectStages.Followup3,
    ASalesCycleProspectStages.PresentationDemo,
    ASalesCycleProspectStages.FutureContact,
    ASalesCycleProspectStages.PriceOffer
  ),
  LastUpdate: enumOrder(
    AProspectLastChangeRanges.TwentyFourHours,
    AProspectLastChangeRanges.ThreeDays,
    AProspectLastChangeRanges.SevenDays,
    AProspectLastChangeRanges.FourteenDays,
    AProspectLastChangeRanges.TwentyOneDays
  ),
  CompanySize: enumOrder(
    ALeadCompanySizes.Small,
    ALeadCompanySizes.Middle,
    ALeadCompanySizes.Corporation,
    ALeadCompanySizes.NotSpecified
  ),
  Profile: enumOrder(
    ASalesCycleIdealCustomerProfiles.Small,
    ASalesCycleIdealCustomerProfiles.Middle,
    ASalesCycleIdealCustomerProfiles.Corporation
  ),
  YearsOfMarket: enumOrder(
    ASalesCycleYearsOfMarkets.Zero,
    ASalesCycleYearsOfMarkets.One,
    ASalesCycleYearsOfMarkets.Two,
    ASalesCycleYearsOfMarkets.ThreeAndMore
  ),
}

/**
 * A helper function ensuring all enum keys are given an order number.
 * Used inside enumOrders().
 *
 * Made because TS does not support exclusive tuples (an array containing all union members exactly once)
 * This allows foreign values and duplicates, but we can live with that.
 */
function enumOrder<T extends string[]>(...members: T): {[k in T[number]]: number} {
  return members.reduce(
    (acc, member, index) => {
      return {...acc, [member]: index}
    },
    {} as ReturnType<typeof enumOrder<T>>
  )
}
