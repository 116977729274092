import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../../components/Alert.tsx"
import {Badge} from "../../../../components/Badge.tsx"
import Button from "../../../../components/Button.tsx"
import {Loading} from "../../../../components/Loading.tsx"
import Pagination from "../../../../components/Pagination.tsx"
import {ProfileImage} from "../../../../components/ProfileImage.tsx"
import {RowCountPicker} from "../../../../components/RowCountPicker.tsx"
import {getCurrentLanguage} from "../../../../i18n.ts"
import {useLeadsForAssignmentQuery} from "../../../../queries/leads.ts"
import {getFullName} from "../../../../utils"
import {apiDateToJS} from "../../../../utils/dateArithmetics.ts"
import {commonTransComponents} from "../../../../utils/i18n.tsx"
import {DeleteModal} from "../../../Leads/components/DeleteModal.tsx"
import {EditingFlyout} from "../../../Leads/components/EditingFlyout.tsx"
import {DeletingContext, EditingContext} from "../../../Leads/context.ts"
import {DataTable} from "../../../Leads/DataTable.tsx"
import {useLeadsFiltering} from "../../../Leads/useLeadsFiltering.ts"
import {AssignmentContext} from "../context.ts"

export const LeadsStep: React.FC<{onClose: () => void}> = ({onClose}) => {
  const {t} = useTranslation()

  const leadsFiltering = useLeadsFiltering()
  const {
    orderBy,
    pageSize,
    setPageSize,
    checkedRowsForQuery,
    positionFilterDebounced,
    segmentFilterDebounced,
    countriesFilterDebounced,
    companySizeFilterDebounced,
    searchStringDebounced,
    clearRows,
  } = leadsFiltering

  const {
    value: {assignment, iteration, salesCycle},
  } = AssignmentContext.useContextOrDie()

  if (assignment == null || iteration == null) {
    throw new Error("Error: assignment or iteration not set")
  }

  const {data, isFetching, error, refetch, pagination} = useLeadsForAssignmentQuery({
    assignmentId: assignment.id,
    orderBy,
    pageSize,
    ids: checkedRowsForQuery,
    positions: positionFilterDebounced.map(opt => opt.value),
    segments: segmentFilterDebounced.map(opt => opt.value),
    countryIds: countriesFilterDebounced.map(opt => Number(opt.value)),
    companySizes: companySizeFilterDebounced.map(opt => opt.value),
    searchString: searchStringDebounced,
  })

  const editContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  if (!data?.data?.meta?.pagination) {
    return <Loading containerClassName={"my-10"} size={"xl"} delayShow={false} />
  }

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  const assignmentSalesPerson = "sales_person" in assignment ? assignment.sales_person : null

  return (
    <EditingContext.Provider value={editContextValue}>
      <DeletingContext.Provider value={deleteContextValue}>
        <div className={"flex flex-col gap-10"}>
          <div className={"flex items-center justify-between gap-8"}>
            <div className={"flex items-center gap-4"}>
              <h1 className={"text-2xl font-semibold"}>{t("Prospects_ImportModal_LeadsStep_Title")}</h1>
              <Badge color={"light-blue"}>
                <Trans
                  i18nKey={"Prospects_ImportModal_LeadsStep_Badge"}
                  components={commonTransComponents}
                  count={data.data.meta.added_prospects_count}
                />
              </Badge>
            </div>
            <Button onClick={onClose}>{t("Prospects_ImportModal_LeadsStep_Done")}</Button>
          </div>

          <div className={"flex items-center justify-between gap-8"}>
            <div>
              <span className={"font-bold"}>{data.data.meta.pagination.total_count ?? 0}</span> {t("Leads_Of")}{" "}
              {data.data.meta.total_leads_for_assignment}
            </div>
            <div className={"flex items-center gap-4"}>
              <ProfileImage
                src={assignmentSalesPerson?.profile_picture_thumbnail_url ?? undefined}
                alt={getFullName(assignmentSalesPerson)}
                className={"size-10"}
              />
              <span>
                {[
                  <span key={"name"} className={"font-bold"}>
                    {getFullName(assignmentSalesPerson) ||
                      ("package_name" in assignment ? assignment.package_name : null)}
                  </span>,
                  salesCycle.company?.name,
                  salesCycle.name,
                  apiDateToJS(iteration.start_date).toLocaleString(getCurrentLanguage(), {
                    month: "long",
                    year: "numeric",
                  }),
                ]
                  .filter(Boolean)
                  .reduce<React.ReactNode[]>((acc, value) => {
                    if (acc.length === 0) {
                      return [value]
                    }
                    return [...acc, " - ", value]
                  }, [])
                  .map((value, i) => (
                    <React.Fragment key={i}>{value}</React.Fragment>
                  ))}
              </span>
            </div>
          </div>

          <DataTable
            data={data.data.leads}
            isInProspects
            isLoading={isFetching}
            refetch={refetch}
            {...leadsFiltering}
          />
          <RowCountPicker total={pagination.total} autoHide rowCount={pageSize} onChange={setPageSize} openUp />
          <Pagination {...pagination} autoHide />
          <EditingFlyout />
          <DeleteModal onDelete={clearRows} />
        </div>
      </DeletingContext.Provider>
    </EditingContext.Provider>
  )
}
