import {produce} from "immer"

import {ASaasCompanyProspectsSalesCycle} from "../../../services/types.generated.ts"

export const salesCycleRemoveIterationsWithoutAssignments = <T extends ASaasCompanyProspectsSalesCycle>(
  salesCycle: T
): T => {
  return produce(salesCycle, draft => {
    draft.sales_cycle_iterations = draft.sales_cycle_iterations.filter(iteration => {
      return iteration.assignments.length > 0
    })
  })
}
