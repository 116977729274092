import React from "react"

import {TFilterOption} from "../../components/AutocompleteFilter"
import {TOption} from "../../components/formElements/Dropdown"
import {DEFAULT_PAGE_SIZE} from "../../components/Pagination"
import {Table} from "../../components/Table"
import {TOrderBy} from "../../components/Table/shared"
import {ALeadCompanySizes} from "../../services/types.generated"
import {useDebouncedValue} from "../../utils/hooks"
import {TLeadsTableColumn} from "./DataTable"

export type TLeadsFiltering = ReturnType<typeof useLeadsFiltering>

export type TCompanySizeOption = TOption<ALeadCompanySizes>

export function useLeadsFiltering() {
  const [orderBy, setOrderBy] = React.useState<TOrderBy<TLeadsTableColumn>>(undefined)
  const [pageSize, setPageSize] = React.useState(DEFAULT_PAGE_SIZE)

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [positionFilter, setPositionFilter] = React.useState<TFilterOption[]>([])
  const positionFilterDebounced = useDebouncedValue(positionFilter)

  const [segmentFilter, setSegmentFilter] = React.useState<TFilterOption[]>([])
  const segmentFilterDebounced = useDebouncedValue(segmentFilter)

  const [countriesFilter, setCountriesFilter] = React.useState<TFilterOption[]>([])
  const countriesFilterDebounced = useDebouncedValue(countriesFilter)

  const [companySizeFilter, setCompanySizeFilter] = React.useState<TCompanySizeOption[]>([])
  const companySizeFilterDebounced = useDebouncedValue(companySizeFilter)

  const [isSelectedOnly, setIsSelectedOnly] = React.useState(false)

  const {checkedRows, checkRow, clearRows, setCheckedRows} = Table.useBasicRowChecking()

  React.useEffect(() => {
    if (checkedRows.length === 0) {
      setIsSelectedOnly(false)
    }
  }, [checkedRows])

  React.useEffect(() => {
    clearRows()
  }, [
    positionFilterDebounced,
    segmentFilterDebounced,
    searchStringDebounced,
    countriesFilterDebounced,
    companySizeFilterDebounced,
    clearRows,
  ])

  const checkedRowsForQuery = React.useMemo(() => {
    if (!isSelectedOnly) {
      return undefined
    }
    return checkedRows as number[]
  }, [isSelectedOnly, checkedRows])

  const isFiltered = React.useMemo(() => {
    return (
      searchStringDebounced.length > 0 ||
      (checkedRowsForQuery ?? []).length > 0 ||
      positionFilterDebounced.length > 0 ||
      segmentFilterDebounced.length > 0 ||
      countriesFilterDebounced.length > 0 ||
      companySizeFilterDebounced.length > 0
    )
  }, [
    searchStringDebounced.length,
    checkedRowsForQuery,
    positionFilterDebounced.length,
    segmentFilterDebounced.length,
    countriesFilterDebounced.length,
    companySizeFilterDebounced.length,
  ])

  return {
    orderBy,
    setOrderBy,
    pageSize,
    setPageSize,
    searchString,
    searchStringDebounced,
    setSearchString,
    positionFilter,
    positionFilterDebounced,
    setPositionFilter,
    segmentFilter,
    setSegmentFilter,
    segmentFilterDebounced,
    countriesFilter,
    setCountriesFilter,
    countriesFilterDebounced,
    companySizeFilter,
    setCompanySizeFilter,
    companySizeFilterDebounced,
    isSelectedOnly,
    setIsSelectedOnly,
    checkedRows,
    checkRow,
    clearRows,
    setCheckedRows,
    checkedRowsForQuery,
    isFiltered,
  }
}
