import React from "react"

import {
  TAdminsProspectsAssignment,
  TAdminsProspectsIteration,
  TSaasCompanyProspectsAssignment,
  TSaasCompanyProspectsIteration,
  TSalespersonProspectsAssignment,
  TSalespersonProspectsIteration,
} from "../../../queries/prospects.ts"
import {
  AAdminProspect,
  AAdminsProspectsSalesCycle,
  ACompanyUserProspect,
  ASaasCompanyProspectsSalesCycle,
  ASaasCompanyUserProspect,
  ASalesPersonProspectsSalesCycle,
} from "../../../services/types.generated"
import {createStateContext} from "../../../utils/context.ts"

export type TRejectProspect = AAdminProspect | ACompanyUserProspect

export type TProspectContext = {
  prospects: TRejectProspect[] | null
  setProspects: (prospect: TRejectProspect[] | null) => void
  rejectionsLeft: number | null
}

const rejectContext = React.createContext<TProspectContext | undefined>(undefined)

export const RejectContextProvider = rejectContext.Provider

export const useRejectContext = () => {
  const ctx = React.useContext(rejectContext)

  if (ctx === undefined) {
    throw new Error("Reject context not found")
  }

  return ctx
}

export const AssignmentContext = createStateContext<{
  assignment: TAdminsProspectsAssignment | TSaasCompanyProspectsAssignment | TSalespersonProspectsAssignment | null
  iteration: TAdminsProspectsIteration | TSaasCompanyProspectsIteration | TSalespersonProspectsIteration | null
  salesCycle: AAdminsProspectsSalesCycle | ASaasCompanyProspectsSalesCycle | ASalesPersonProspectsSalesCycle
} | null>("assignment")

export type TDeletingProspect = AAdminProspect | ASaasCompanyUserProspect

export const DeletingContext = createStateContext<TDeletingProspect | number[] | null>("deleting")

export type TEditingProspect = AAdminProspect | ASaasCompanyUserProspect

export const EditingContext = createStateContext<TEditingProspect | null>("editing")
export const WarningsContext = createStateContext<{[key in keyof TEditingProspect]?: string}>("warnings")
export const IsUploadingContext = createStateContext<boolean>("isUploading")
